<template>
  <div class="col main_words_wrapper_container">
    <!-- Words List  -->
    <!-- Large Screens  -->
    <server-table
      :count="$store.getters['words/total']"
      :DataItems="all_words"
      @Refresh="Refresh"
      @export="Export"
      import_url="words/import"
      @Export_Bulk="Export_Bulk"
      @addBulk="add_bulk_dialog = true"
      :filter="filter"
      @reset="filter = {}"
      :loading="$store.getters['words/usersLoad']"
      class="
        table table-striped table-hover
        dataTable
        dtr-inline
        hidden-sm-only hidden-xs-only
      "
      :title="`${localization('Words')}`"
      :fileLoading="isLoading"
      :allow_bulk_process="true"
      admin_permission="admin_words"
      edit_permission="add_words"
    >
      <template #columns>
        <!--        <el-table-column prop="id" label="#" width="100" sortable></el-table-column>-->
        <el-table-column
          prop="word"
          :label="`${localization('Word')}`"
          sortable
          min-width="150"
        ></el-table-column>
        <el-table-column
          prop="versions"
          :label="`${localization('Alternative')}`"
          min-width="400"
        >
          <template slot-scope="scope">
            <el-tag
              v-for="(tag, i) in scope.row.versions"
              :key="i"
              style="border-radius: 16px; padding: 1px 17px; margin-right: 2px"
              v-if="tag != '' || null"
            >
              <span v-if="tag != '' || null">{{ tag }}</span>
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="category"
          :label="`${localization('Category')}`"
          sortable
          min-width="150"
        ></el-table-column>
        <el-table-column
          prop="language"
          :label="`${localization('Language')}`"
          sortable
          min-width="150"
        >
          <template slot-scope="scope">
            <div>
              {{ language(languages, scope.row.language) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="pattern"
          :label="`${localization('Pattern')}`"
          sortable
          min-width="200"
        ></el-table-column>
        <el-table-column
          prop="removed"
          sortable
          :label="`${localization('State')}`"
          min-width="120"
        >
          <template slot-scope="scope">
            <l-button
              outline
              round
              size="sm"
              :type="scope.row.removed == 0 ? 'success' : 'danger'"
              style="font-size: 11px; margin: 1px; padding: 2px 7px"
            >
              {{
                scope.row.removed == 0
                  ? `${localization("Active")}`
                  : `${localization("Inactive")}`
              }} </l-button
            >&nbsp;
          </template>
        </el-table-column>
        <el-table-column prop="" label="_" min-width="80">
          <template slot-scope="scope">
            <div class="td-actions">
              <a
                v-if="hasPermission('edit_words')"
                v-tooltip.top-center="`${localization('Edit Word')}`"
                @click="open_word_dialog(2, scope.row)"
                class="btn btn-warning btn-link btn-xs"
              >
                <i class="fa fa-edit fa-lg"></i>
              </a>
              <a
                v-if="hasPermission('edit_words')"
                v-tooltip.top-center="
                  scope.row.removed == 0
                    ? `${localization('Delete Word')}`
                    : `${localization('Active Word')}`
                "
                @click="remove(scope.row.id, scope.row.removed)"
                :class="
                  scope.row.removed == 0
                    ? `btn btn-danger btn-link btn-xs`
                    : `btn btn-success btn-link btn-xs`
                "
              >
                <i v-if="scope.row.removed == 0" class="fa fa-trash"></i>
                <i v-else class="fa fa-check"></i>
              </a>
            </div>
          </template>
        </el-table-column>
      </template>
      <template #filter>
        <el-row :gutter="10">
          <el-col :md="12">
            <el-select
              v-model="filter.language"
              :placeholder="`${localization('Language')}`"
              clearable
              class="select-default w-100 mt-2"
            >
              <el-option
                v-for="(language, i) in $store.getters['words/languages']"
                :key="i"
                :label="language.title"
                :value="language.id"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :md="12">
            <el-select
              v-model="filter.pattern"
              :placeholder="`${localization('Pattern')}`"
              class="mt-2 select-default w-100"
            >
              <el-option
                v-for="(pattern, i) in patterns"
                :key="i"
                :label="pattern.label"
                :value="pattern.label"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
      </template>
      <template #action>
        <div type="info" class="ml-2" @click="open_word_dialog(1, {})">
          <el-icon class="el-icon-plus"></el-icon>
          {{ localization("Add Word") }}
        </div>
      </template>
    </server-table>

    <!-- //? Medium & Small Screens  -->
    <server-table
      :count="$store.getters['words/total']"
      :DataItems="all_words"
      @Refresh="Refresh"
      @export="Export"
      import_url="words/import"
      @Export_Bulk="Export_Bulk"
      @addBulk="add_bulk_dialog = true"
      :filter="filter"
      @reset="filter = {}"
      :loading="$store.getters['words/usersLoad']"
      class="
        table table-striped table-hover
        dataTable
        dtr-inline
        hidden-md-only hidden-lg-only hidden-xl-only
      "
      :title="`${localization('Words')}`"
      :fileLoading="isLoading"
      :allow_bulk_process="true"
      admin_permission="admin_words"
      edit_permission="add_words"
    >
      <template #columns>
        <!--        <el-table-column prop="id" label="#" width="100" sortable></el-table-column>-->
        <el-table-column
          prop="word"
          :label="`${localization('Word')}`"
          sortable
        >
          <template slot-scope="scope">
            <div
              class="
                d-flex
                flex-column flex-sm-row
                justify-content-sm-between
                align-items-sm-center
              "
            >
              {{ scope.row.word }}

              <div class="d-flex justify-content-between">
                <a
                  v-tooltip.top-center="`${localization('Show Word')}`"
                  @click="open_show_word_dialog(scope.row)"
                  class="btn btn-info btn-link btn-xs"
                >
                  <i class="fa fa-eye"></i>
                </a>
                <a
                  v-if="hasPermission('edit_words')"
                  v-tooltip.top-center="`${localization('Edit Word')}`"
                  @click="open_word_dialog(2, scope.row)"
                  class="btn btn-warning btn-link btn-xs"
                >
                  <i class="fa fa-edit fa-lg"></i>
                </a>
                <a
                  v-if="hasPermission('edit_words')"
                  v-tooltip.top-center="
                    scope.row.removed == 0
                      ? `${localization('Delete Word')}`
                      : `${localization('Active Word')}`
                  "
                  @click="remove(scope.row.id, scope.row.removed)"
                  :class="
                    scope.row.removed == 0
                      ? `btn btn-danger btn-link btn-xs`
                      : `btn btn-success btn-link btn-xs`
                  "
                >
                  <i v-if="scope.row.removed == 0" class="fa fa-trash"></i>
                  <i v-else class="fa fa-check"></i>
                </a>
              </div>
            </div>
          </template>
        </el-table-column>
      </template>
      <template #filter>
        <el-row :gutter="10">
          <el-col :md="12">
            <el-select
              v-model="filter.language"
              :placeholder="`${localization('Language')}`"
              clearable
              class="select-default w-100 mt-2"
            >
              <el-option
                v-for="(language, i) in $store.getters['words/languages']"
                :key="i"
                :label="language.title"
                :value="language.id"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :md="12">
            <el-select
              v-model="filter.pattern"
              :placeholder="`${localization('Pattern')}`"
              class="mt-2 select-default w-100"
            >
              <el-option
                v-for="(pattern, i) in patterns"
                :key="i"
                :label="pattern.label"
                :value="pattern.label"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
      </template>
      <template #action>
        <div type="info" class="ml-2" @click="open_word_dialog(1, {})">
          <el-icon class="el-icon-plus"></el-icon>
          {{ localization("Add Word") }}
        </div>
      </template>
    </server-table>

    <!-- //? Add or edit word dialog  -->
    <el-dialog
      :title="
        type == 1
          ? `${localization('Add Word')}`
          : `${localization('Edit Word')}`
      "
      width="60%"
      :visible.sync="word_dialog"
      top="4vh"
      class="add_words_dialog_wrapper"
    >
      <el-form
        :model="word"
        style="width: 100%"
        ref="ruleFormAdd"
        :rules="rules"
        label-position="top"
      >
        <el-form-item
          :label="`${localization('Word')}`"
          style="width: 100%"
          prop="word"
        >
          <el-input
            type="text"
            v-model="word.word"
            :placeholder="`${localization('Please enter word')}`"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <div class="d-flex flex-column flex-lg-row justify-content-between">
          <el-form-item
            :label="`${localization('Pattern')}`"
            prop="pattern"
            class="w-100"
          >
            <el-select
              v-model="word.pattern"
              :placeholder="`${localization('Please select a pattern')}`"
              style="width: 100%"
            >
              <el-option
                v-for="(pattern, i) in patterns"
                :key="i"
                :label="pattern.label"
                :value="pattern.label"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-form-item
          :label="`${localization('Language')}`"
          style="width: 100%"
          prop="language"
        >
          <el-select
            v-model="word.language"
            :placeholder="`${localization('Please select language')}`"
            style="width: 100%"
          >
            <el-option
              v-for="(language, i) in $store.getters['words/languages']"
              :key="i"
              :label="language.title"
              :value="language.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="`${localization('Alternative')}`"
          style="width: 100%"
          prop="versions"
        >
          <el-select
            v-model="word.versions"
            :no-data-text="`${localization('Add Word')}`"
            multiple
            filterable
            allow-create
            default-first-option
            class="select-primary"
            style="width: 100%"
          >
          </el-select>
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="
          dialog-footer
          dialog_footer
        "
      >
        <el-button
          class="text-center text-capitalize save_button delete_button"
          @click="word_dialog = false"
          >{{ localization("Cancel") }}</el-button
        >
        <el-button
          type="primary"
          class="text-center text-capitalize save_button"
          @click="save_word"
          >{{ localization("Save") }}</el-button
        >
      </span>
    </el-dialog>

    <!-- //? Add bulk Dialog  -->
    <el-dialog
      :title="`${localization('Add Bulk')}`"
      :visible.sync="add_bulk_dialog"
      top="4vh"
      width="60%"
      :close-on-click-modal="false"
      class="add_words_dialog_wrapper"
    >
      <el-form
        :model="addBulkModel"
        v-loading="isLoading"
        ref="bulkRuleForm"
        :rules="bulkRules"
        label-position="top"
      >
        <el-row :gutter="20">
          <el-col
            ><el-form-item
              :label="`${localization('Language')}`"
              prop="language"
            >
              <el-select
                v-model="addBulkModel.language"
                :placeholder="`${localization('Please select language')}`"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in languages"
                  :key="index"
                  :label="item.title"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" type="flex" class="row-bg" justify="end">
          <el-col :lg="12">
            <el-form-item class="w-100">
              <el-upload
                class="w-100"
                style="
                  display: grid;
                  grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
                  grid-gap: 1rem;
                "
                :auto-upload="false"
                :file-list="fileList"
                :on-change="handleUploadChange"
                :on-success="Refresh"
                accept=".xlsx"
                :before-upload="beforeUpload"
                :action="import_src_bulk"
                :data="{ language: addBulkModel.language }"
                :disabled="!addBulkModel.language"
                ref="upload"
              >
                <el-button
                  @click="validateFileUpload"
                  slot="trigger"
                  class="w-100"
                  type="primary"
                  >{{ localization("Select file") }}</el-button
                >
              </el-upload>
            </el-form-item></el-col
          >
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer dialog_footer">
        <el-button
          class="text-center text-capitalize save_button delete_button"
          @click="add_bulk_dialog = false"
          >{{ localization("Cancel") }}</el-button
        >
        <el-button
          type="primary"
          class="text-center text-capitalize save_button "
          @click="submitUpload"
          :disabled="isLoading"
          >{{ localization("Submit") }}</el-button
        >
      </div>
    </el-dialog>

    <!-- Show words dialog on medium & small screens  -->
    <el-dialog
      :title="`${localization('Word')}`"
      :visible.sync="show_word_dialog"
      top="4vh"
      width="90%"
      class="add_words_dialog_wrapper"
    >
      <Info :items="shown_word" />
      <div
        class="
          dialog-footer
          dialog_footer
        "
      >
        <el-button
          type="danger"
          class="text-center ml-lg-2 mx-auto d-block  my-1"
          @click="show_word_dialog = false"
        >
          {{ localization("Close") }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ServerTable from "../../components/DataTablePagination.vue";
import Info from "../../components/Cards/Info";
import "element-ui/lib/theme-chalk/display.css";
import Swal from "sweetalert2";
import { mapState } from "vuex";
export default {
  name: "index",
  components: { ServerTable, Info },
  data() {
    return {
      word_dialog: false,
      show_word_dialog: false, // small and medium screens dialog
      shown_word: {},
      rules: {
        category: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        pattern: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change"
          }
        ],
        word: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        language: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change"
          }
        ]
      },
      type: 0,
      word: { language: null },
      filter: {},
      localSort: { col: "id", order: "DESC" },
      // preserve sorting concept to local component

      ageList: [
        { value: 1, label: "6-12 years old" },
        { value: 2, label: "13 -21 years old" },
        {
          value: 3,
          label: "22 or older"
        }
      ],
      categories: [{ label: "workforce" }, { label: "default" }],
      patterns: [
        { label: "Confluence" },
        { label: "Precision" },
        { label: "Sequence" },
        { label: "Technical Reasoning" }
      ],

      add_bulk_dialog: false,
      addBulkModel: {},
      isLoading: false,
      fileList: [],
      bulkRules: {
        language: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ]
      }
    };
  },
  computed: {
    languages: function() {
      return this.$store.getters["words/languages"];
    },
    // all_words() {
    //   return this.$store.getters["words/words"];
    // },
    // all_words_test() {
    //   return this.$store.state.words.words;
    // },
    import_src_bulk() {
      return `${process.env.VUE_APP_BASE_URL}/words-language/import`;
    },
    ...mapState({
      all_words: state => state.words.words
    })
  },
  methods: {
    Refresh(query) {
      this.$store.dispatch("words/words", { query: query });
      // stop loading after importing bulk
      this.isLoading = false;
      // close add bulk dialog if opened
      if (this.add_bulk_dialog) this.add_bulk_dialog = false;
      // empty file list ofr bulk upload
      this.fileList = [];
    },

    save_word() {
      this.$refs["ruleFormAdd"].validate(valid => {
        if (valid) {
          if (this.type == 1) {
            this.$store
              .dispatch("words/add_word", { query: this.word })
              .then(_ => {
                this.Refresh();
                this.word_dialog = false;
                Swal.fire({
                  title: "",
                  icon: "success",
                  text: "Words has been added.",
                  type: "success",
                  confirmButtonClass: "btn btn-success",
                  buttonsStyling: false
                });
              });
          } else {
            this.$store
              .dispatch("words/save_word", {
                id: this.word.id,
                query: this.word
              })
              .then(_ => {
                this.Refresh({
                  limit: 4,
                  offset: 0,
                  order_by: this.localSort.col,
                  order_direction: this.localSort.order
                });
                this.word_dialog = false;
                Swal.fire({
                  title: "",
                  icon: "success",
                  text: "Words has been updated.",
                  type: "success",
                  confirmButtonClass: "btn btn-success",
                  buttonsStyling: false
                });
              });
          }
        } else {
          return false;
        }
      });
    },
    open_word_dialog(type, item) {
      this.word = { ...item };
      this.type = type;
      this.word_dialog = true;
    },
    // dialog for medium & small screens
    open_show_word_dialog(item) {
      this.shown_word = Object.entries(item)
        .map(([key, value]) => {
          return { key, value };
        })
        .filter(
          item =>
            item.key !== "id" &&
            item.key !== "removed" &&
            item.key !== "isEnabled" &&
            item.key !== "old_id"
        );
      this.show_word_dialog = true;
    },
    remove(id, removed, query) {
      Swal.fire({
        title: "",
        text:
          removed == 0
            ? `Are you sure to delete this word?`
            : "Are you sure to Active this word?",
        icon: "error",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-danger",
        confirmButtonText: removed == 0 ? "Delete" : "Confirm",
        buttonsStyling: false
      }).then(date => {
        if (date.isConfirmed) {
          this.$store.dispatch("words/delete_word", id).then(_ => {
            // this.Refresh(null);
            // this.$store.dispatch("words/words", { query: query });
            this.$store.getters["words/words"];
            Swal.fire({
              title: removed == 0 ? `` : "",
              text:
                removed == 0
                  ? "Word has been deleted."
                  : "Word has been activated.",
              icon: "success",
              confirmButtonClass: "btn btn-success",
              buttonsStyling: false
            });
          });
        }
      });
    },
    language(languages, id) {
      let language = languages.filter(el => el.id == id)[0];
      if (language) {
        return language.title;
      }
      return "-";
    },
    Export(query) {
      this.$store.dispatch("words/Export", { query: query });
    },
    Export_Bulk() {
      this.$store.dispatch("words/ExportBulk");
    },
    beforeUpload(file) {
      // start loading before uploading
      this.isLoading = true;
    },
    handleUploadChange(file, fileList) {
      this.fileList = fileList.slice(-1);
    },
    validateFileUpload() {
      this.$refs["bulkRuleForm"].validate(valid => {
        if (valid) {
          return true;
        } else {
          return false;
        }
      });
    },
    submitUpload() {
      this.$refs["bulkRuleForm"].validate(valid => {
        if (valid) {
          this.$refs.upload.submit();
          Swal.fire({
            title: "",
            icon: "success",
            text: "Bulk has been added.",
            type: "success",
            confirmButtonClass: "btn btn-success",
            buttonsStyling: false
          });
        } else {
          return false;
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.container--fluid {
  min-height: 80vh !important;
}

.el-tag + .el-tag {
  margin-left: 5px;
}

.el-table__expanded-cell[class*="cell"] {
  padding: 5px !important;
}

::v-deep .el-table .cell {
  word-break: break-word;
}

.dialog_footer {
  display: flex;
  justify-content: end;
  align-items: center;

  @media (min-width: 320px) and (max-width: 991px) {
    justify-content: center;
  }
  .save_button {
    background-color: #23ccef;
    border-color: #23ccef;
    min-width: 150px;
    color: #fff;
    @media (min-width: 320px) and (max-width: 767px) {
      min-width: 124px;
    }
    &.delete_button {
      background-color: #f56c6c;
      border-color: #f56c6c;
    }
  }
}
</style>

<style lang="scss">
.main_words_wrapper_container {
  .add_words_dialog_wrapper {
    .el-dialog {
      @media (min-width: 320px) and (max-width: 767px) {
        width: 90% !important;
      }
    }
  }
}
</style>
